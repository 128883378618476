import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { getStringStyleParamsValue } from './stylesParams';
import {
  AlignmentOptions,
  ImagePositionOptions,
  ImageSizeOptions,
  ServiceListLayoutOptions,
} from '../../types/types';
import {
  getServiceListLayoutDefaultValue,
  getAlternateImageInfoSidesDefaultValue,
  getServiceImagePositionDefaultValue,
  getServiceImageSizeDefaultValue,
  getTextAlignmentDefaultValue,
  getTitleAlignmentDefaultValue,
  getMaxCardsPerRowDefaultValue,
  getGridCardSpacingDefaultValue,
  getStripCardSpacingDefaultValue,
  getCardSpacingDefaultValue,
} from './settingsParams-utils';

type CustomCssVarsArgs = Parameters<CustomCssVarsFn>[0];

const getServiceImagePositionFromTpaDate = (tpaData: any) => {
  const serviceImagePosition =
    tpaData?.serviceImagePosition as ImagePositionOptions;
  if (serviceImagePosition) {
    return serviceImagePosition;
  }
  return getServiceImagePositionDefaultValue();
};

const getAlternateImageInfoSidesFromTpaDate = (tpaData: any) => {
  const alternateImageInfoSides =
    tpaData?.alternateImageInfoSides as ImagePositionOptions;
  return alternateImageInfoSides || getAlternateImageInfoSidesDefaultValue();
};

const getServiceTextAlignmentFromTpaDate = (
  tpaData: any,
  isMobile: boolean,
) => {
  const textAlignment = isMobile
    ? ((tpaData?.['textAlignment▶︎m'] ||
        tpaData?.textAlignment) as unknown as AlignmentOptions)
    : (tpaData?.textAlignment as unknown as AlignmentOptions);
  const preset = tpaData?.[isMobile ? 'presetId▶︎m' : 'presetId'];
  return textAlignment || getTextAlignmentDefaultValue(preset, isMobile);
};

const getServiceTitleAlignemntFromTpaDate = (
  tpaData: any,
  isMobile: boolean,
) => {
  const titleAlignment = isMobile
    ? ((tpaData?.['titleAlignment▶︎m'] ||
        tpaData?.titleAlignment) as unknown as AlignmentOptions)
    : (tpaData?.titleAlignment as unknown as AlignmentOptions);

  return titleAlignment || getTitleAlignmentDefaultValue();
};

const getServiceImageSizeFromTpaDate = (tpaData: any, isMobile: boolean) => {
  const serviceImageSize = isMobile
    ? tpaData?.['serviceImageSize▶︎m']
    : (tpaData?.serviceImageSize as unknown as ImageSizeOptions);
  return serviceImageSize || getServiceImageSizeDefaultValue(isMobile);
};

const getMaxCardsPerRowFromTpaDate = (tpaData: any, isMobile: boolean) => {
  const maxCardsPerRow = (isMobile
    ? tpaData?.['cardsPerRow▶︎m']
    : tpaData?.cardsPerRow) as unknown as number;
  return maxCardsPerRow || getMaxCardsPerRowDefaultValue(isMobile);
};

const getServiceListLayoutFromTpaData = (tpaData: any, isMobile: boolean) => {
  const serviceLiseLayout = tpaData?.[
    isMobile ? 'serviceListLayout▶︎m' : 'serviceListLayout'
  ] as unknown as ServiceListLayoutOptions;
  if (serviceLiseLayout) {
    return serviceLiseLayout;
  }
  const preset = tpaData?.[isMobile ? 'presetId▶︎m' : 'presetId'];
  return getServiceListLayoutDefaultValue(preset, isMobile);
};

const getGridCardSpacingFromTpaDate = (tpaData: any, isMobile: boolean) => {
  const gridCardsSpacing = (isMobile
    ? tpaData?.['gridCardsSpacing▶︎m'] || tpaData?.gridCardsSpacing
    : tpaData?.gridCardsSpacing) as unknown as number;
  return gridCardsSpacing || getGridCardSpacingDefaultValue(isMobile);
};

const getStripCardSpacingFromTpaDate = (tpaData: any, isMobile: boolean) => {
  const stripCardSpacing = (isMobile
    ? tpaData?.['stripCardSpacing▶︎m'] || tpaData?.stripCardSpacing
    : tpaData?.stripCardSpacing) as unknown as number;
  return stripCardSpacing || getStripCardSpacingDefaultValue();
};

const getCardSpacingFromTpaDate = (tpaData: any, isMobile: boolean) => {
  const cardSpacing = (isMobile
    ? tpaData?.['cardSpacing▶︎m'] || tpaData?.cardSpacing
    : tpaData?.cardSpacing) as unknown as number;
  return cardSpacing || getCardSpacingDefaultValue();
};

const calculateCardSpacing = (
  layout: ServiceListLayoutOptions,
  customCssVarsArgs: CustomCssVarsArgs,
): number => {
  const { styleParams, tpaData, isMobile } = customCssVarsArgs;
  switch (layout) {
    case ServiceListLayoutOptions.GRID:
      return (
        styleParams.numbers.gridCardsSpacing ||
        getGridCardSpacingFromTpaDate(tpaData, isMobile)
      );
    case ServiceListLayoutOptions.STRIP:
      return (
        styleParams.numbers.stripCardSpacing ||
        getStripCardSpacingFromTpaDate(tpaData, isMobile)
      );
    default:
      return (
        styleParams.numbers.cardSpacing ||
        getCardSpacingFromTpaDate(tpaData, isMobile)
      );
  }
};

const calculateMaxCardsPerRow = (
  layout: ServiceListLayoutOptions,
  customCssVarsArgs: CustomCssVarsArgs,
): number => {
  const { styleParams } = customCssVarsArgs;
  if (layout === ServiceListLayoutOptions.GRID) {
    return (
      styleParams.numbers.maxCardsPerRow ||
      getMaxCardsPerRowFromTpaDate(
        customCssVarsArgs.tpaData,
        customCssVarsArgs.isMobile,
      )
    );
  }
  return 1;
};

export const cssVarsGenerator = (customCssVarsArgs: CustomCssVarsArgs) => {
  const { isMobile, isRTL, styleParams, tpaData } = customCssVarsArgs;
  const isServiceImageVisible = styleParams.booleans.isServiceImageVisible;
  const serviceImagePosition =
    (getStringStyleParamsValue(
      styleParams.strings.serviceImagePosition,
    ) as ImagePositionOptions) ?? getServiceImagePositionFromTpaDate(tpaData);
  const isServiceImageOnRight =
    serviceImagePosition === ImagePositionOptions.RIGHT;
  const alternateImageInfoSides =
    styleParams.booleans.alternateImageInfoSides ??
    getAlternateImageInfoSidesFromTpaDate(tpaData);
  const serviceTextAlignment =
    (getStringStyleParamsValue(
      styleParams.strings.serviceTextAlignment,
    ) as AlignmentOptions) ??
    getServiceTextAlignmentFromTpaDate(tpaData, isMobile);
  const serviceTitleAlignemnt =
    (getStringStyleParamsValue(
      styleParams.strings.titleAlignment,
    ) as AlignmentOptions) ??
    getServiceTitleAlignemntFromTpaDate(tpaData, isMobile);
  const serviceImageSize =
    (getStringStyleParamsValue(
      styleParams.strings.serviceImageSize,
    ) as ImageSizeOptions) ?? getServiceImageSizeFromTpaDate(tpaData, isMobile);
  const serviceListLayout = getServiceListLayoutFromTpaData(tpaData, isMobile);
  const isGridLayout = serviceListLayout === ServiceListLayoutOptions.GRID;
  const maxCardsPerRow = calculateMaxCardsPerRow(
    serviceListLayout,
    customCssVarsArgs,
  );
  const cardsSpacing = calculateCardSpacing(
    serviceListLayout,
    customCssVarsArgs,
  );
  const displayDividersBetweenCards =
    serviceListLayout === ServiceListLayoutOptions.STRIP;
  const dividerWidth = styleParams.numbers.servciesDividerWidthInStrip;
  const gridPadding =
    serviceListLayout === ServiceListLayoutOptions.STRIP
      ? `${cardsSpacing / 2 + dividerWidth}px`
      : 'unset';
  return {
    bodyWidgetGrid: () => {
      return {
        bodyMaxWidth: isGridLayout ? '616px' : 'unset',
        bodyWidth: isGridLayout ? '100%' : 'unset',
        bodyMarginLeft: isGridLayout ? 'auto' : 'unset',
        bodyMarginRight: isGridLayout ? 'auto' : 'unset',
      };
    },
    gridCardsAndSpacing: () => {
      return {
        cardsPerRow: maxCardsPerRow,
        cardsSpacing: `${cardsSpacing}px`,
        cardsDividerVisibility: displayDividersBetweenCards ? 'block' : 'hide',
        dividerWidth,
        gridPadding,
      };
    },
    classicImagePosition: () => {
      if (alternateImageInfoSides) {
        return isServiceImageOnRight
          ? { evenImageDirection: 'rtl', oddImageDirection: 'ltr' }
          : { evenImageDirection: 'ltr', oddImageDirection: 'rtl' };
      } else {
        return isServiceImageOnRight
          ? { evenImageDirection: 'rtl', oddImageDirection: 'rtl' }
          : { evenImageDirection: 'ltr', oddImageDirection: 'ltr' };
      }
    },
    overlappingImagePosition: () => {
      const overlappingGridAreaImageInRight = `". media"
        "info media"
        ". media"`;

      const overlappingGridAreaImageInLeft = `"media margin-top"
        "media info"
        "media margin-bottom"`;

      if (alternateImageInfoSides) {
        return isServiceImageOnRight
          ? {
              evenOverlappingGridArea: overlappingGridAreaImageInRight,
              oddOverlappingGridArea: overlappingGridAreaImageInLeft,
              evenOverlappingImageMargin: '0 0 0 -60px',
              oddOverlappingImageMargin: '0 -60px 0 0',
              evenOverlappingTemplateColumns: isServiceImageVisible
                ? '50% 1fr'
                : '1fr 0%',
              oddOverlappingTemplateColumns: isServiceImageVisible
                ? '1fr 50%'
                : '0% 1fr',
            }
          : {
              evenOverlappingGridArea: overlappingGridAreaImageInLeft,
              oddOverlappingGridArea: overlappingGridAreaImageInRight,
              evenOverlappingImageMargin: '0 -60px 0 0',
              oddOverlappingImageMargin: '0 0 0 -60px',
              evenOverlappingTemplateColumns: isServiceImageVisible
                ? '1fr 50%'
                : '0% 1fr',
              oddOverlappingTemplateColumns: isServiceImageVisible
                ? '50% 1fr'
                : '1fr 0%',
            };
      } else {
        return isServiceImageOnRight
          ? {
              evenOverlappingGridArea: overlappingGridAreaImageInRight,
              oddOverlappingGridArea: overlappingGridAreaImageInRight,
              evenOverlappingImageMargin: '0 0 0 -60px',
              oddOverlappingImageMargin: '0 0 0 -60px',
              evenOverlappingTemplateColumns: isServiceImageVisible
                ? '50% 1fr'
                : '1fr 0%',
              oddOverlappingTemplateColumns: isServiceImageVisible
                ? '50% 1fr'
                : '1fr 0%',
            }
          : {
              evenOverlappingGridArea: overlappingGridAreaImageInLeft,
              oddOverlappingGridArea: overlappingGridAreaImageInLeft,
              evenOverlappingImageMargin: '0 -60px 0 0',
              oddOverlappingImageMargin: '0 -60px 0 0',
              evenOverlappingTemplateColumns: isServiceImageVisible
                ? '1fr 50%'
                : '0% 1fr',
              oddOverlappingTemplateColumns: isServiceImageVisible
                ? '1fr 50%'
                : '0% 1fr',
            };
      }
    },
    serviceDetailsTextAlignment: () => {
      switch (serviceTextAlignment) {
        case AlignmentOptions.CENTER:
          return {
            serviceDetailsTextAlign: 'center',
            serviceDetailsMarginLeft: 'auto',
            serviceDetailsMarginRight: 'auto',
          };
        case AlignmentOptions.LEFT:
          return {
            serviceDetailsTextAlign: 'left',
            serviceDetailsMarginLeft: 0,
            serviceDetailsMarginRight: 'auto',
          };
        case AlignmentOptions.RIGHT:
          return {
            serviceDetailsTextAlign: 'right',
            serviceDetailsMarginLeft: 'auto',
            serviceDetailsMarginRight: 0,
          };
      }
    },
    serviceTitleAlignment: () => {
      switch (serviceTitleAlignemnt) {
        case AlignmentOptions.CENTER:
          return {
            titlePaddingLeft: '10%',
            titlePaddingRight: '10%',
            titleTextAlign: 'center',
          };
        case AlignmentOptions.LEFT:
          return {
            titlePaddingLeft: 'initial',
            titlePaddingRight: '20%',
            titleTextAlign: 'left',
          };
        case AlignmentOptions.RIGHT:
          return {
            titlePaddingLeft: '20%',
            titlePaddingRight: 'initial',
            titleTextAlign: 'right',
          };
      }
    },
    serviceImageSize: () => {
      switch (serviceImageSize) {
        case ImageSizeOptions.SMALL:
          return { serviceImageSize: isMobile ? '40px' : '84px' };
        case ImageSizeOptions.MEDIUM:
          return { serviceImageSize: isMobile ? '56px' : '110px' };
        case ImageSizeOptions.LARGE:
          return { serviceImageSize: isMobile ? '84px' : '140px' };
      }
    },
  } satisfies Record<string, () => ReturnType<CustomCssVarsFn>>;
};

export const generateAllCssVars: CustomCssVarsFn = (args) => {
  const cssVarsGenerators = cssVarsGenerator(args);
  type keysType = keyof typeof cssVarsGenerators;
  return (Object.keys(cssVarsGenerators) as keysType[]).reduce((css, key) => {
    const cssGeneratorFn = cssVarsGenerators[key];
    const generatedCss = cssGeneratorFn();
    return { ...css, ...generatedCss };
  }, {});
};
